<template>
  <div class="row">
    <div class="col-12 col-md">
      <img class="mb-2" src="@/assets/logo.png" alt="" width="24" height="19" />
      <small class="d-block mb-3 text-muted">&copy; 2024</small>
    </div>
    <div class="col-6 col-md">
      <h5>Контакти</h5>
      <ul class="list-unstyled text-small">
        <li class="mb-1">093 531-89-98</li>
        <li class="mb-1">096 531-89-98</li>
        <li class="mb-1">095 531-89-98</li>

        <li class="mb-1">
          <a class="link-secondary text-decoration-none" href="#">viber</a>
        </li>
        <li class="mb-1">
          <a class="link-secondary text-decoration-none" href="#">telegram</a>
        </li>
      </ul>
    </div>
    <div class="col-6 col-md">
      <h5>Данні</h5>
      <ul class="list-unstyled text-small">
        <li class="mb-1">
          <a class="link-secondary text-decoration-none" href="#">Підтримка</a>
        </li>
        <li class="mb-1">
          <a class="link-secondary text-decoration-none" href="#"
            >Resource name</a
          >
        </li>
        <li class="mb-1">
          <a class="link-secondary text-decoration-none" href="#"
            >Another resource</a
          >
        </li>
        <li class="mb-1">
          <a class="link-secondary text-decoration-none" href="#"
            >Final resource</a
          >
        </li>
      </ul>
    </div>
    <div class="col-6 col-md">
      <h5>About</h5>
      <ul class="list-unstyled text-small">
        <li class="mb-1">
          <a class="link-secondary text-decoration-none" href="#">Team</a>
        </li>
        <li class="mb-1">
          <a class="link-secondary text-decoration-none" href="#">Locations</a>
        </li>
        <li class="mb-1">
          <a class="link-secondary text-decoration-none" href="#">Privacy</a>
        </li>
        <li class="mb-1">
          <a class="link-secondary text-decoration-none" href="#">Terms</a>
        </li>
      </ul>
    </div>
  </div>
</template>
