<template>
  <div class="container">
    <div
      class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start"
    >
      <div class="me-auto">
        <a
          href="/"
          class="d-flex align-items-center mb-2 mb-lg-0 text-white text-decoration-none"
        >
          <img
            class="mb-2 me-2"
            src="@/assets/logo.png"
            alt=""
            width="30"
            height="30"
          />
          <span class="text-warning">HolderPos</span>
        </a>
      </div>

      <button
        class="btn btn-outline-light d-block d-lg-none"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasExample"
        aria-controls="offcanvasExample"
      >
        <i class="fa-regular fa-bars"></i>
      </button>
      <div class="text-end d-none d-lg-block">
        <ul
          class="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0"
        >
          <li><a href="/" class="nav-link px-2 text-secondary">Головна</a></li>
          <li>
            <a href="#forwho" class="nav-link px-2 text-white">Рішення</a>
          </li>
          <li>
            <a href="#futures" class="nav-link px-2 text-white">Можливості</a>
          </li>

          <li>
            <a href="#howitwork" class="nav-link px-2 text-white">Опис</a>
          </li>

          <li>
            <a href="#pricing" class="nav-link px-2 text-white">Ціна</a>
          </li>

          <li>
            <a href="#registration" class="btn btn-warning btn-sm me-1">
              <i class="fa-regular fa-user-tie"></i> реєстрація
            </a>
          </li>

          <li>
            <a
              href="https://console.holderpos.com.ua"
              target="_blank"
              class="btn btn-outline-warning btn-sm"
            >
              <i class="fa-light fa-desktop"></i> консоль
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div
    class="offcanvas offcanvas-start"
    tabindex="-1"
    id="offcanvasExample"
    aria-labelledby="offcanvasExampleLabel"
  >
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offcanvasExampleLabel">Offcanvas</h5>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body">
      <div>
        Some text as placeholder. In real life you can have the elements you
        have chosen. Like, text, images, lists, etc.
      </div>
      <div class="dropdown mt-3">
        <button
          class="btn btn-secondary dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
        >
          Dropdown button
        </button>
        <ul class="dropdown-menu">
          <li><a class="dropdown-item" href="#">Action</a></li>
          <li><a class="dropdown-item" href="#">Another action</a></li>
          <li><a class="dropdown-item" href="#">Something else here</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script setup></script>
<style scoped></style>
