import { createRouter, createWebHistory } from "vue-router";
const routes = [
  {
    path: "/",
    name: "MainPage",
    meta: { name: "Головна" },
    component: () => import("@/MainPage.vue"),
  },
  {
    path: "/article",
    name: "ArticlePage",
    meta: { name: "Стаття" },
    component: () => import("@/ArticlePage.vue"),
  },
  {
    path: "/registration",
    name: "registration",
    meta: { name: "Стаття" },
    component: () => import("@/RegistrationPage.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
