import { createApp } from "vue";
import App from "./App.vue";
/* eslint-disable */
import router from "./router";
import { createHead } from "@unhead/vue";
const head = createHead();
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "@/css/fortawesome6/css/all.css";

import Popper from "vue3-popper";
const Vue = createApp(App);
// eslint-disable-next-line
Vue.component("Popper", Popper);
Vue.use(router, head);
Vue.mount("#app");
